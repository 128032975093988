import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageWrap from '../components/page-wrap'

const About = props => {
  return (
    <Layout>
      <SEO title="About" />
      <PageWrap>
        <h1>About</h1>
        <p>Who am I?</p>
      </PageWrap>
    </Layout>
  )
}

export default About
